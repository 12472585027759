<template>
  <div>
    <b-overlay :show="isLoading">
      <b-row>
        <b-col cols="6">
          <b-form-input ref="txtSearch" v-model="searchString" placeholder="Filter Curriculum Objects" :disabled="readOnly"></b-form-input>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-button @click="revertAll" :disabled="readOnly">
                <font-awesome-icon icon="fa-solid fa-backward-fast" />Revert All
              </b-button>
            </b-col>
            <b-col>
              <b-button @click="undoLast" :disabled="undoAvailable || readOnly">
                <font-awesome-icon icon="fa-solid fa-backward" />Undo
              </b-button>
              <span style="display: none;">{{undoQueueLength}}</span>
            </b-col>
            <b-col>
              <b-button variant="danger" @click="clearList" :disabled="readOnly">
                <font-awesome-icon icon="fa-solid fa-trash-can" />Clear List
              </b-button>
            </b-col>
            <b-col><img v-if="isSaving" src="/media/loading/gear.gif" class="save-spinner"/></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="source-list">
          <b-card>
            <b-card-header>
              <b-container fluid>
                <b-row>
                  <b-col class="container-header">Available Objects</b-col>
                  <b-col cols="2">
                    <b-button @click="moveAllItems" :disabled="readOnly">Move All
                      <font-awesome-icon icon="fa-solid fa-forward"/>
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-card-header>
            <b-card-body>
              <div class="vue-grid-layout source-grid">
                <div class="vue-grid-item" v-for="item in sourceList" :key="item.i"
                  :class="dragItemCustomClass"
                  :draggable="dragable"
                  @drag="drag"
                  @dragend="dragend">
                  <font-awesome-icon class="document-logo" :icon="parseResourceTypeFA(item.resourceType)" />
                  <!-- <img :src="parseResourceType(item.resourceType)" :alt="item.resourceName" /> -->
                  <span class="title"></span><span>{{item.resourceName}}</span><br/>
                  <span class="title"></span><span>({{item.language ? item.language : 'EN'}})</span>
                  <!--
                    This is a required element for the Drag and Drop functionality
                    in order to pass along the appropriate index that was selected
                    from the source list.
                  -->
                  <input type="hidden" :value="item.i" name="sourceListIndex" />
                  <!-- END -->
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <b-card-header>
              <b-container fluid>
                <b-row>
                  <b-col class="container-header">Playlist Objects</b-col>
                  <b-col cols="3">
                    <b-button @click="sortByName" :disabled="readOnly">Sort By Name
                      <font-awesome-icon icon="fa-solid fa-arrow-down-a-z"/>
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-card-header>
            <b-card-body>
              <div class="dest-list-wrapper">
                <grid-layout class="destination-list"
                  id="content"
                  ref="filmstrip"
                  :layout.sync="filmStrip"
                  :col-num="1"
                  :row-height="20"
                  :is-dragable.sync="dragable"
                  :is-resizable="resizable"
                  :vertical-compact="true"
                  :use-css-transform="false">
                  <grid-item 
                    v-for="item in filmStrip" :key="item.i"
                    :static="item.static"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :is-draggable="dragable"
                    @move="itemMove"
                    @moved="itemMoved"
                    :class="(item.static ? 'static' : '') + ' ' + dragItemCustomClass">
                    <font-awesome-icon class="document-logo" :icon="parseResourceTypeFA(item.resourceType)" />
                    <!-- <img :src="parseResourceType(item.resourceType)" :alt="item.title" /> -->
                    <span class="title"></span><span>{{item.resourceName}}</span><br/>
                    <span class="title"></span><span>({{item.language ? item.language : 'EN'}})</span>
                    <font-awesome-icon v-if="dragable" @click="removeItem(item)" class="remove-item" icon="fa-solid fa-trash-can" />
                  </grid-item>
                </grid-layout>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout';
import HierarchySvc from '../services/hierarchy.service';
import AuthorSvc from '../services/authoring.service';

let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 2, h: 3, i: null};

export default {
  components: {
    GridLayout,
    GridItem,
  },
  data() {
    return {
      layout: [],
      filmStrip: [],
      initialState: {},
      undoQueue: [],
      moveStarted: false,
      dragable: true,
      resizable: false,
      index: 0,
      searchString: '',
      isLoading: true,
      isSaving: false,
      removeFromSource: true,
      mode: 'PLAY_LIST',
      curriculumGuid: '',
      hierarchyGuid: '',
      hierarchyCode: '',
      webConUser: '',
      assetId: 0,
      templateId: 0,
      objectId: 0,
    };
  },
  mounted() {
    console.log("[FilmStrip]: Adding Event Listener 'dragover' to document");
    document.addEventListener('dragover', this.dragover, false);

    console.log("[FilmStrip]: Adding Event Listener 'message' to window");
    // Event Listener for info from WebCon
    window.addEventListener('message', this.receiveDataFromParent, false);

    console.log("[FilmStrip]: Posting message to parent window");
    // Let the parent window know we are loaded
    window.parent.postMessage({ message: 'IFRAME_LOADED' }, '*');

    this.$refs.txtSearch.$el.focus();

    if (process.env.VUE_APP_MODE === 'Development') {
      // For Debug Purposes
      this.curriculumGuid = '6A993627-C8A8-4E79-91F1-6A8D71C8EF59';
      this.hierarchyGuid = 'F3FBFFD3-4297-4BD3-8736-00D984550130';
      this.assetId = 125;
      this.templateId = 10;
      this.objectId = 1271;
      this.webConUser = 'rrastelli@pcgus.com';
      this.dragable = false;

      this.fetchData();
    }
  },
  destroyed() {
    document.removeEventListener('dragover', this.dragover);

    // Remove Event Listender for info from WebCon
    window.removeEventListener('message', this.receiveDataFromParent);
  },
  computed: {
    sourceList() {
      return this.layout.filter(item => {
        return item.resourceName.toLowerCase().includes(this.searchString.toLowerCase()) && !item.isHidden && (item.objectId !== this.objectId);
      });
    },
    undoQueueLength() {
      return this.undoQueue.length;
    },
    undoLastIndex() {
      return this.undoQueueLength - 1;
    },
    undoAvailable() {
      return this.undoQueueLength === 0;
    },
    readOnly() {
      return !this.dragable;
    },
    dragItemCustomClass() {
      return this.readOnly ? 'read-only' : '';
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      let generatedAssetInputs = [];

      await Promise.all([
        HierarchySvc.getHierarchyObjects(this.curriculumGuid, this.hierarchyGuid, this.objectId)
          .then(response => {
            this.layout = HierarchySvc.mapHierarchyObjectsToUIObjects(response);
          }),
        AuthorSvc.getGeneratedAssetInputs(this.assetId)
          .then(response => {
            generatedAssetInputs = response;
          }),
      ]);

      for (let index = 0; index < generatedAssetInputs.length; index++) {
        const assetInput = generatedAssetInputs[index];
        const layoutItem = this.layout.filter(item => {
          return item.objectId === assetInput.objectId;
        });
        if (layoutItem.length === 1) {
          layoutItem[0].isHidden = true;
          this.filmStrip.push(
            { x:0,y:(index * 3),w:2,h:3,i:index,
              isHidden: false,
              imageUrl: layoutItem[0].iconUrl,
              title: layoutItem[0].resourceName,
              description: '',
              resourceName: layoutItem[0].resourceName,
              resourceType: layoutItem[0].resourceType,
              resourceId: layoutItem[0].resourceId,
              objectId: layoutItem[0].objectId,
              language: layoutItem[0].language,
            });
        }
      }
      this.initialState = {
        layout: JSON.parse(JSON.stringify(this.layout)),
        filmStrip: JSON.parse(JSON.stringify(this.filmStrip)),
      };

      this.isLoading = false;
    },
    receiveDataFromParent(event) {
      if (event.origin !== process.env.VUE_APP_PARENT_IFRAME) {
        // console.log(`Expected Origin mismatch. Received ${event.origin}`);
        return;
      }
      console.debug('Recieved Message from Parent - Event: %O', event);

      const { data } = event;
      const { configuration } = data;
      const { sdkConfiguration } = configuration;
      const { fieldConfiguration } = configuration;

      if (data.message !== 'SET_CONFIGURATION') {
        console.log(`Expected Message mismatch. Received ${event.message}`);
        return;
      }
      if (!sdkConfiguration) {
        console.log('Received invalid SdkCnfiguratin Object. Check Webcon configurations');
        return;
      }

      if (!fieldConfiguration) {
        this.dragable = true;
      } else {
        this.dragable = fieldConfiguration.editMode != 'ReadOnly' && fieldConfiguration.editMode != 'ReadOnlyHtml';
      }

      if (sdkConfiguration.hierarchyGuid &&
        sdkConfiguration.curriculumGuid &&
        sdkConfiguration.templateId &&
        sdkConfiguration.assetId &&
        sdkConfiguration.objectId &&
        sdkConfiguration.webconUser) {
          this.hierarchyGuid = sdkConfiguration.hierarchyGuid;
          this.curriculumGuid = sdkConfiguration.curriculumGuid;
          this.templateId = sdkConfiguration.templateId;
          this.assetId = sdkConfiguration.assetId;
          this.objectId = sdkConfiguration.objectId;
          this.webConUser = sdkConfiguration.webconUser;

          // Fetch Data for the load
          this.fetchData();
        } else {
          console.error('Invalid Configuration Object received from Webcon. Check the confiugraitons and reload this page. %O', sdkConfiguration);
        }
    },
    removeItem(item) {
      this.setUndoState();
      const itemIndex = this.filmStrip.findIndex(ai => { 
        return ai.objectId === item.objectId;
      });
      this.filmStrip.splice(itemIndex, 1);
      const originalItem = this.layout.filter(l => {
        return l.objectId === item.objectId
      });
      if (originalItem.length === 1) {
        originalItem[0].isHidden = false;
      }
      this.saveOrder();
    },
    clearList() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to clear this entire list?', {
        title: 'Confirm Clearing List',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        hideHeaderClose: true,

      }).then(value => {
        if (value) {
          this.setUndoState();
          this.filmStrip = [];
          this.layout.forEach(item => {
            item.isHidden = false;
          });
          this.saveOrder();
        }
      });
    },
    parseResourceType(type) {
      switch (type) {
        case 'GoogleDoc':
          return './media/icons/docs.svg'
        case 'GoogleSlide':
          return './media/icons/slides.svg'
        case 'GoogleSheet':
          return './media/icons/sheets.svg'
        case 'Word':
        case 'WebUrl':
        case 'EmbeddedVideo':
        case 'Video':
        case 'Audio':
        case 'Rtf':
        case 'Image':
          
          break;
      
        default:
          break;
      }
    },
    parseResourceTypeFA(type) {
      switch (type) {
        case 'Word':
        case 'GoogleDoc':
          return 'fa-solid fa-file-lines';
        case 'GoogleSlide':
          return 'fa-solid fa-file-powerpoint';
        case 'GoogleSheet':
          return 'fa-solid fa-table';
        case 'Template':
          return 'fa-solid fa-file-code';
        case 'EmbeddedVideo':
        case 'Video':
          return 'fa-solid fa-file-video';
        case 'Audio':
          return 'fa-solid fa-file-audio';
        case 'Image':
          return 'fa-solid fa-file-image';      
        case 'WebUrl':
          return 'fa-solid fa-file fa-link';
        case 'Rtf':
        default:
          return 'fa-solid fa-file'
      }
    },
    saveOrder() {
      this.isSaving = true;
      const newList = this.filmStrip.sort((a, b) => {
        if (a.y === b.y) {
          return a.x - b.x;
        }
        return a.y - b.y;
      });

      AuthorSvc.saveGeneratedAssetInputs(this.assetId, this.templateId, this.webConUser, newList)
        .then(() => {
          this.isSaving = false;
        });
    },
    setUndoState() {
      this.undoQueue.push({
        layout: JSON.parse(JSON.stringify(this.layout)),
        filmStrip: JSON.parse(JSON.stringify(this.filmStrip)),
      });
      if (this.undoQueue.length > 10) {
        this.undoQueue.shift();
      }
    },
    undoLast() {
      const lastState = this.undoQueue.pop();

      this.layout = JSON.parse(JSON.stringify(lastState.layout));
      this.filmStrip = JSON.parse(JSON.stringify(lastState.filmStrip));
      this.saveOrder();
    },
    revertAll() {
      this.layout = JSON.parse(JSON.stringify(this.initialState.layout));
      this.filmStrip = JSON.parse(JSON.stringify(this.initialState.filmStrip));
      this.undoQueue = [];
      this.saveOrder();
    },
    itemMove() {
      if (this.moveStarted) {
        return;
      }
      this.moveStarted = true;
      this.setUndoState();

    },
    itemMoved() {
      this.saveOrder();
      this.moveStarted = false;
    },
    dragover(e) {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    },
    drag() {
      if (this.readOnly) {
        return;
      }
        let parentRect = document.getElementById('content').getBoundingClientRect();
        let mouseInGrid = false;
        if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
            mouseInGrid = true;
        }
        if (mouseInGrid === true && (this.filmStrip.findIndex(item => item.i === 'drop')) === -1) {
            this.filmStrip.push({
                x: (this.filmStrip.length * 2) % (this.colNum || 12),
                y: this.filmStrip.length + (this.colNum || 12), // puts it at the bottom
                w: 2,
                h: 2,
                i: 'drop',
            });
        }
        let index = this.filmStrip.findIndex(item => item.i === 'drop');
        if (index !== -1) {
          try {
            this.$refs.filmstrip.$children[this.filmStrip.length].$refs.item.style.display = "none";
          // eslint-disable-next-line no-empty
          } catch {
            // Sometimes, this is not found, and it is ok, not really an error condition
            // this empty try/catch stops console errors, but requires the eslint line above
            // to supress any lint errors.
          }
          let el = this.$refs.filmstrip.$children[index];
          el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
          let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);
          if (mouseInGrid === true) {
              this.$refs.filmstrip.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 1, 1);
              DragPos.i = String(index);
              DragPos.x = this.filmStrip[index].x;
              DragPos.y = this.filmStrip[index].y;
          }
          if (mouseInGrid === false) {
              this.$refs.filmstrip.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 1, 1);
              this.filmStrip = this.filmStrip.filter(obj => obj.i !== 'drop');
          }
        }
    },
    dragend(e) {
      if (this.readOnly) {
        return;
      }
      let parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
          mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        //let targetElement = e.targetElement.$children.filter((t) => { t.});
        this.$refs.filmstrip.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1);
        this.filmStrip = this.filmStrip.filter(obj => obj.i !== 'drop');

        // Record Undo here
        this.setUndoState();

        // Requires a hidden named element of 'sourceListIndex' in the HTML for each list item
        const dragListIndex = e.srcElement.children.namedItem('sourceListIndex').value;
        
        // The following line is responsible for hiding the nodes in the source list.
        if (this.removeFromSource) {
          this.layout[dragListIndex].isHidden = true;
        }

        this.filmStrip.push({
            x: DragPos.x,
            y: DragPos.y,
            w: DragPos.w,
            h: DragPos.h,
            i: DragPos.i,
            originalIndex: dragListIndex,
            imageUrl: this.layout[dragListIndex].imageUrl,
            title: this.layout[dragListIndex].title,
            description: this.layout[dragListIndex].description,
            resourceName: this.layout[dragListIndex].resourceName,
            resourceType: this.layout[dragListIndex].resourceType,
            resourceId: this.layout[dragListIndex].resourceId,
            objectId: this.layout[dragListIndex].objectId,
            language: this.layout[dragListIndex].language,
        });

        // Save to API here
        this.saveOrder();

        this.$refs.filmstrip.dragEvent('dragend', DragPos.i, DragPos.x,DragPos.y,1,1);
        try {
            this.$refs.filmstrip.$children[this.filmStrip.length].$refs.item.style.display="block";
        // eslint-disable-next-line no-empty
        } catch {
          // Sometimes, this is not found, and it is ok, not really an error condition
          // this empty try/catch stops console errors, but requires the eslint line above
          // to supress any lint errors.
        }
      }
      this.$refs.txtSearch.$el.focus();
    },
    moveAllItems() {
      this.setUndoState();

      let currentLength;

      this.sourceList.forEach(item => {
        item.isHidden = true;
        currentLength = this.filmStrip.length;
        this.filmStrip.push({
          x: 0,
          y: (currentLength) * 3,
          w: 2,
          h: 3,
          i: currentLength,
          originalIndex: currentLength,
          imageUrl: item.imageUrl,
          title: item.title,
          description: item.description,
          resourceName: item.resourceName,
          resourceType: item.resourceType,
          resourceId: item.resourceId,
          objectId: item.objectId,
          language: item.language,
        });
      });

      this.saveOrder();
    },
    sortByName() {
      this.setUndoState();

      this.filmStrip = this.filmStrip.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });

      for (let index = 0; index < this.filmStrip.length; index++) {
        const element = this.filmStrip[index];

        element.y = index;
        element.index = index;        
      }

      this.saveOrder();
    },
  }
}
</script>

<style>
.modal-footer .btn {
    color: #333;
    background-color: #fdfdfd;
    border: 1px solid #ababab;
    border-radius: 0;
    padding: 6px 12px;
    font-size: 12px;
    text-align: center;
    line-height: 1.42857143;
    transition: all .05s ease-in;
    cursor: pointer;
}
.modal-footer .btn.btn-danger:hover {
  background-color: rgb(240, 166, 166);
}
.modal-footer .btn.btn-secondary:hover {
  background-color: rgb(190, 190, 190);
}
</style>

<style scoped>
.card-body {
  padding: 0;
}
.btn {
    color: rgba(51, 51, 51, 0.9);
    background-color: #fdfdfd;
    border: 1px solid #ababab;
    border-radius: 0;
    padding: 6px 12px;
    font-size: 12px;
    text-align: center;
    line-height: 1.42857143;
    transition: all .05s ease-in;
    cursor: pointer;
    width: 100%;
}
.btn svg {
  padding-right: .5em;
}.save-spinner {
  height: 30px;
}
.container-header {
  text-align: left;
  font-weight: 500;
  font-size: 1.2em;
}
.card-header {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.source-list {
  padding-right: 15px;
}
.destination-list {
  padding-left: 15px;
}
.vue-grid-layout {
    background: #f5f5f6;
    min-height: 475px;
}
.dest-list-wrapper {
  height: 475px;
  max-height: 475px;
  overflow-y: scroll;
}
.vue-grid-layout.source-grid {
  padding: 5px;
  height: 475px;
  overflow-y: scroll;
}
.vue-grid-layout.source-grid .vue-grid-item.read-only:hover {
  cursor: default;
}
.vue-grid-layout.source-grid .vue-grid-item,
.vue-grid-layout.destination-list .vue-grid-item {
  width: 95% !important;
  height: 50px;
  margin: 5px;
}
.vue-grid-layout .vue-grid-item span.title{
  font-weight: bold;
  padding-left: 3px;
}
.vue-grid-layout.source-grid .vue-grid-item:hover {
  cursor: move;
}
.vue-grid-item {
  border-radius: .5em;
  border-color: RGB(205, 205, 250);
  overflow: hidden;
  text-align: left;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: white;
    border: 1px solid RGB(205, 205, 205);
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-grid-item.static {
  border-width: 2px;
}
.vue-grid-layout.source-grid .vue-grid-item img {
  padding: 3px 0;
}
.vue-grid-item img {
  width: 25%;
  height: 100%;
  float: left;
  padding: 5px 0;
}
.vue-grid-layout.source-grid .vue-grid-item .document-logo {
  font-size: 2em;
  margin-top: 3px;
}
.vue-grid-item .document-logo {
    font-size: 4em;
    padding: 5px;
    float: left;
    margin: 2px 15px;
    color: #518FF5;
    background-color: white;
    border-radius: 5px;
}
.vue-grid-item p {
  text-align: left;
  vertical-align: top;
  margin-top: 2px;
}
.vue-grid-item p span {
  font-weight: bold;
  margin-right: 3px;
}
.vue-grid-item .remove-item {
  position: absolute;
  right: 10px;
  bottom: 5px;
  cursor: default;
  color: RGBA(51, 51, 51, 0.6);
}
.vue-grid-item .tile-count {
  position: absolute;
  right: 0;
  bottom: 0;
}
.vue-grid-item .tile-count.moved {
  font-weight: bold;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
</style>