<template>
  <div>
    <b-overlay :show="isLoading">
      <b-row>
        <b-col :cols="drawerCols" v-show="drawerVisible" :class="drawerClass">
          <b-row>
            <span class="drawer-header">Available Custom Fields</span>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body
                class="level-based-tags"
                header="Hierarchy Fields">
                <b-card-body>
                  <ul>
                    <li v-for="item in assetItems" :key="item.id">
                      <!-- Asset Mode - Show Tag and Value; No COPY icon -->
                      <!-- Other Modes - Show Tag; Show Copy icon -->                      
                      <font-awesome-icon v-if="display_mode !== 'ASSET'" class="icon copy-icon" icon="fa-solid fa-copy" :title="item.tag" @click="copyToClipboard(item)"/>
                      <span :title="item.toolTip">{{ item.text }}</span>
                      <span v-if="display_mode === 'ASSET'"> - {{ item.toolTip }}</span>
                    </li>
                  </ul>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="display_mode === 'TEMPLATE' || display_mode === 'ASSET'">
            <b-col>
              <b-card no-body
                class="level-based-tags"
                header="Template Fields">
                <b-card-body>
                  <ul>
                    <!-- TEMPLATE Mode: Show {{Tag}} and Copy Icon -->
                    <!-- ASSET Mode: Show {{Tag}} and Show Map Icon for Tags that done exist in CDF
                        Map Icon bring up Modal with Drop Down of Objects in Current Hierarchy
                        for user to select document to map tag to -->
                    <li v-for="item in templateInputs" :key="item.id">
                      <font-awesome-icon v-if="display_mode === 'TEMPLATE'" class="icon copy-icon" icon="fa-solid fa-copy" :title="item.tag" @click="copyToClipboard(item)"/>
                      <font-awesome-icon v-if="display_mode === 'ASSET' && item.unmapped && !item.objectId" class="icon map-icon" icon="fa-solid fa-plug" title="Unmapped Input; Click to map to Curriculum Object" @click="mapTagToObject(item)" />
                      <font-awesome-icon v-if="display_mode === 'ASSET' && item.unmapped && item.objectId" class="icon map-icon" icon="fa-solid fa-plug-circle-check" title="Unmapped Input; Click to map to Curriculum Object" @click="editMapTagToObject(item)" />
                      <span>{{ item.text }}</span>
                    </li>
                  </ul>
                </b-card-body>
              </b-card>
              <input type="hidden" ref="copyTarget"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1" v-if="drawerAvailable">
          <b-button @click="toggleDrawer">
            <font-awesome-icon :icon="drawerIcon"/>
          </b-button>
        </b-col>
        <b-col :cols="editorCols">
          <iframe v-if="contentUrl.length > 0" :src="contentUrl" :allow="crossOriginAllow" />
        </b-col>
      </b-row>
    </b-overlay>
    <b-modal id="mapTagModal"
      title="Map Tags to Curriculum Object"
      ok-title="Save"
      hide-header-close
      @cancel="modalCancelClick"
      @ok="modalOkClick"
      @shown="modalShown"
      size="lg">
      
      <b-overlay :show="modalInputs.isLoading">
        <b-row>
          <b-col><span class="label">Input Tag:</span> {{modalInputs.currentGeneratedAssetInput.tag}}</b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="label">Curriculum Object:</span>
          </b-col>
          <b-col class="right">
            <b-form-input v-model="modalInputs.objectFilter" placeholder="Filter Objects"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-list-group class="list-group" ref="listGroup">
              <b-list-group-item v-for="item in objectList" :key="item.objectId"
                @click="listItemSelect(item, $event)"
                :class="item.objectId === modalInputs.selectedValue ? 'active' : ''"
                :ref="item.objectId === modalInputs.selectedValue ? 'activeItem' : undefined">
                <span class="label">Resource Name:</span><span class="data-item">{{item.resourceName}}</span><br/>
                <span class="label">Resource Type:</span><span class="data-item">{{item.resourceType}}</span>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="label">Selected Resource:</span> {{ modalInputs.selectedObject.resourceName }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span v-if="modalInputs.showValidation" class="validation-err">You must select a Curriculum Object before save</span>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
  import AuthoringSvc from '../services/authoring.service';
  import HierarchySvc from '../services/hierarchy.service';

  export default {
    data() {
      return {
        isLoading: true,
        showDrawer: true,
        display_mode: 'OBJECT',
        curriculumGuid: '',
        hierarchyGuid: '',
        objectId: '',
        templateId: '',
        assetId: '',
        projectId: 0,
        hierarchyLvl: 0,
        webconUser: '',
        resourceUrl: '',
        resourceId: '',
        templateUrl: '',
        templateResourceUrl: '',
        assetItems: [],
        templateInputs: [],
        showTemplate: false,
        readOnly: false,
        currentObject: {},
        currentAsset: {},
        currentTemplate: {},
        modalInputs: {
          objectFilter: '',
          curriculumObjects: [],
          templateTag: '',
          selectedValue: -1,
          selectedObject: {},
          selectedListItem: {},
          currentGeneratedAssetInput: {},
          showValidation: false,
        },
      };
    },
    mounted() {
      // Event Listener for info from WebCon
      window.addEventListener('message', this.receiveDataFromParent, false);

      // Let the parent window know we are loaded
      window.parent.postMessage({ message: 'IFRAME_LOADED' }, '*');

      if (process.env.VUE_APP_MODE === 'Development') {
        this.readOnly = false;
        // For Debug Purposes
        this.display_mode = 'ASSET';
        this.curriculumGuid = '4352213C-8873-458C-9442-718107D614D1';
        this.hierarchyGuid = 'B7D78C57-ECE3-41D5-9D68-DB9355E169F1';
        this.objectId = 1555;
        this.assetId = 152;
        this.templateId = 71;
        this.projectId = 0;
        this.hierarchyLvl = 0;
        this.webconUser = 'rrastelli@gedu-demo-pcg.com';

        // this.assetItems = [
        //   { text: 'Template Item A', tag: '{{itemA}}', assetId: 0 },
        //   { text: 'Template Item B', tag: '{{ItemB}}', assetId: 1 },
        //   { text: 'Template Item C', tag: '{{ItemC}}', assetId: 2 },
        //   { text: 'Template Item D', tag: '{{ItemD}}', assetId: 3 },
        // ];

        this.fetchData();
      }
    },
    computed: {
      contentUrl() {
        switch (this.display_mode) {
          case 'TEMPLATE':
            return this.readOnly ? `https://docs.google.com/viewer?srcid=${this.currentTemplate.resourceId}&pid=explorer&efh=false&a=v&chrome=false&embedded=true` : this.currentTemplate.locationUrl;
          case 'OBJECT':
            return this.currentObject.resourceURL;
          case 'ASSET':
            return this.currentAsset.templateResourceURL;
          default:
            return this.resourceUrl;
        }
      },
      templateViewText() {
        if (this.showTemplate) {
          return 'Show Document';
        }
        return 'Show Template';
      },
      editorCols() {
        return this.showDrawer ? 8 : 11;
      },
      drawerAvailable() {
        return true;
      },
      drawerVisible() {
        return this.showDrawer;
      },
      drawerCols() {
        return this.showDrawer ? 3 : 0;
      },
      drawerIcon() {
        return this.showDrawer ? 'fa-solid fa-backward' : 'fa-solid fa-forward'
      },
      drawerClass() {
        return this.showDrawer ? '' : 'hidden';
      },
      crossOriginAllow() {
        return `clipboard-write ${window.location} https://docs.google.com`;
      },
      objectList() {
        return this.modalInputs.curriculumObjects.filter(co => {
          return co.resourceName.toLowerCase().includes(this.modalInputs.objectFilter.toLowerCase());
        });
      }
    },
    methods: {
      async fetchData() {
        let tags = [];
        switch (this.display_mode) {
          case 'OBJECT':
            await Promise.all([
              AuthoringSvc.getObject(this.objectId, this.webconUser)
                .then(result => {
                  this.currentObject = result;
                  this.resourceUrl = result.resourceURL;
                  this.resourceId = result.resourceId;
                }),
              HierarchySvc.getHierarchyCDFs(this.curriculumGuid, this.hierarchyGuid)
                .then(result => {
                  result.forEach(input => {
                    this.assetItems.push({
                      id: input.id,
                      tag: `{{${input.valueKey.replace(" ", "_")}}}`,
                      text: input.valueKey,
                    });
                  });
                })
            ]);

            this.isLoading = false;
            break;
          case 'TEMPLATE':
            await Promise.all([
              AuthoringSvc.getTemplate(this.templateId, this.webconUser)
                .then(result => {
                  this.currentTemplate = result;
                  this.resourceUrl = result.locationUrl;
                }),
              AuthoringSvc.getTemplateInputs(this.templateId, this.webconUser)
                .then(result => {
                  result.forEach(input => {
                    this.templateInputs.push({
                      id: input.id,
                      tag: input.tag,
                      text: input.tag,
                    });
                  });
                }),
              HierarchySvc.getProjectHierarchyCDFs(this.projectId, this.hierarchyLvl)
                .then(result => {
                  result.forEach(input => {
                    this.assetItems.push({
                      id: input.id,
                      tag: `{{${input.valueKey.replace(" ", "_")}}}`,
                      text: `{{${input.valueKey.replace(" ", "_")}}}`,
                      toolTip: input.value
                    });
                  });
                })
            ]);

            this.isLoading = false;
            break;
          case 'ASSET':
            await Promise.all([
              AuthoringSvc.getObject(this.objectId, this.webconUser)
                .then(result => {
                  this.currentObject = result;
                  this.resourceId = result.resourceId;
                  this.resourceUrl = result.resourceURL
                }),
              AuthoringSvc.getTemplate(this.templateId, this.webconUser)
                .then(result => {
                  this.currentTemplate = result;
                  this.templateUrl = result.resourceId;
                }),
              HierarchySvc.getHierarchyCDFs(this.curriculumGuid, this.hierarchyGuid)
                .then(result => {
                  result.forEach(input => {
                    this.assetItems.push({
                      id: input.id,
                      tag: `{{${input.valueKey.replace(" ", "_")}}}`,
                      text: input.valueKey,
                      toolTip: input.value.startsWith('<') ? '[Formatted Text]' : input.value,
                    });
                  });
                }),
              AuthoringSvc.getGeneratedAssetInputs(this.assetId, this.webconUser)
                .then(result => {
                  result.forEach(input => {
                    this.templateInputs.push({
                      id: input.id,
                      tag: input.tag,
                      text: input.tag,
                      objectId: input.objectId,
                      unmapped: false,
                    });
                  });
                }),
              AuthoringSvc.getAsset(this.assetId, this.webconUser)
                .then(result => {
                  this.currentAsset = result;
                  this.templateResourceUrl = result.templateResourceURL;
                }),
            ]);

            // Process the responses to find Unmapped Templates
            this.templateInputs.forEach(template => {
              console.log(template);
              tags = this.assetItems.filter(a => a.tag === template.tag);
              console.log(tags);
              if (tags.length === 1) {
                template.unmapped = false;
              } else {
                template.unmapped = true;
              }
            })
            this.isLoading = false;
            break;
          case 'REVIEW':
            await Promise.all([
              AuthoringSvc.getObject(this.objectId, this.webconUser)
                .then(result => {
                  this.resourceUrl = result.resourceURL;
                }),
              HierarchySvc.getHierarchyCDFs(this.curriculumGuid, this.hierarchyGuid)
                .then(result => {
                  result.forEach(input => {
                    this.assetItems.push({
                      id: input.id,
                      tag: `{{${input.valueKey.replace(" ", "_")}}}`,
                      text: input.valueKey,
                    });
                  });
                })
            ]);

            this.isLoading = false;
            break
          default:
            this.$bvModal.msgBoxOk(`Invalid Display Mode specified: ${this.display_mode}`, {
              title: 'Configuration Error',
            })
            break;
        }
      },
      receiveDataFromParent(event) {
        console.log('Recieved Message from Parent - Event: %O', event);
        if (event.origin !== process.env.VUE_APP_PARENT_IFRAME) {
          //console.log(`Expected Origin mismatch. Received ${event.origin}`);
          return;
        }

        const { data } = event;
        const { configuration } = data;
        const { sdkConfiguration } = configuration;
        const { fieldConfiguration } = configuration;

        if (data.message !== 'SET_CONFIGURATION') {
          console.log(`Expected Message mismatch. Received ${event.message}`);
          return;
        }
        if (!sdkConfiguration) {
          console.log('Received invalid SdkCnfiguratin Object. Check Webcon configurations');
          return;
        }

        if (!fieldConfiguration) {
          this.readOnly = false;
        } else {
          this.readOnly = fieldConfiguration.editMode === 'ReadOnly' || fieldConfiguration.editMode === 'ReadOnlyHtml';
        }

        if (sdkConfiguration.mode) {
          this.display_mode = sdkConfiguration.mode;
          switch (sdkConfiguration.mode) {
            case 'TEMPLATE':
              if (sdkConfiguration.templateId &&
                sdkConfiguration.hierarchyLvl &&
                sdkConfiguration.projectId) {
                  this.templateId = sdkConfiguration.templateId;
                  this.hierarchyLvl = sdkConfiguration.hierarchyLvl;
                  this.projectId = sdkConfiguration.projectId;
              } else {
                console.error('Invalid Configuration Object received from Webcon. Check the configurations and reload this page. %0', sdkConfiguration);
                return;
              }
              break;
            case 'OBJECT':
              if (sdkConfiguration.objectId &&
                sdkConfiguration.curriculumGuid &&
                sdkConfiguration.hierarchyGuid) {
                  this.objectId = sdkConfiguration.objectId;
                  this.curriculumGuid = sdkConfiguration.curriculumGuid;
                  this.hierarchyGuid = sdkConfiguration.hierarchyGuid;
              } else {
                console.error('Invalid Configuration Object received from Webcon. Check the configurations and reload this page. %0', sdkConfiguration);
                return;
              }
              break;
            case 'ASSET':
              if (sdkConfiguration.assetId &&
                sdkConfiguration.objectId &&
                sdkConfiguration.templateId &&
                sdkConfiguration.curriculumGuid &&
                sdkConfiguration.hierarchyGuid) {
                  this.assetId = sdkConfiguration.assetId;
                  this.objectId = sdkConfiguration.objectId;
                  this.templateId = sdkConfiguration.templateId;
                  this.curriculumGuid = sdkConfiguration.curriculumGuid;
                  this.hierarchyGuid = sdkConfiguration.hierarchyGuid;
              } else {
                console.error('Invalid Configuration Object received from Webcon. Check the configurations and reload this page. %0', sdkConfiguration);
                return;
              }
              break;
            case 'REVIEW':
              if (sdkConfiguration.objectId &&
                sdkConfiguration.curriculumGuid &&
                sdkConfiguration.hierarchyGuid) {
                  this.objectId = sdkConfiguration.objectId;
                  this.curriculumGuid = sdkConfiguration.curriculumGuid;
                  this.hierarchyGuid = sdkConfiguration.hierarchyGuid;
              } else {
                console.error('Invalid Configuration Object received from Webcon. Check the configurations and reload this page. %0', sdkConfiguration);
                return;
              }
              break;
            default:
              console.error('Invalid Configuration Object received from Webcon. Check the configurations and reload this page. %0', sdkConfiguration);
              return;
          }
        } else {
          console.error('Invalid Configuration Object received from Webcon. Check the configurations and reload this page. %0', sdkConfiguration);
          return;
        }

        if (sdkConfiguration.webconUser) {
            this.webconUser = sdkConfiguration.webconUser;

            // Fetch Data for the load
            this.fetchData();
          } else {
            console.error('Invalid Configuration Object received from Webcon. Check the confiugraitons and reload this page. %O', sdkConfiguration);
          }
      },
      async mapTagToObject(item) {
        if (this.readOnly) {
          return;
        }
        this.modalInputs.isLoading = true;
        this.$bvModal.show('mapTagModal');

        this.modalInputs.currentGeneratedAssetInput = item;

        const curObjs = await HierarchySvc.getHierarchyObjects(this.curriculumGuid, this.hierarchyGuid, this.objectId);

        this.modalInputs.curriculumObjects = curObjs;
        this.modalInputs.isLoading = false;
      },
      async editMapTagToObject(item) {
        if (this.readOnly) {
          return;
        }
        this.modalInputs.isLoading = true;
        this.$bvModal.show('mapTagModal');

        this.modalInputs.currentGeneratedAssetInput = item;

        this.modalInputs.selectedValue = item.objectId;

        HierarchySvc.getHierarchyObjects(this.curriculumGuid, this.hierarchyGuid, this.objectId)
          .then(curObjs => {
            this.modalInputs.curriculumObjects = curObjs;

            var selectedItem = curObjs.filter(co => {
              return co.objectId === item.objectId;
            });

            if (selectedItem.length === 1) {
              this.modalInputs.selectedObject = selectedItem[0];
            }

            this.modalInputs.isLoading = false;
          });
      },
      modalShown() {
        this.$nextTick(() => {
          if (this.$refs.activeItem && this.$refs.activeItem.length && this.$refs.activeItem.length === 1) {
            this.$refs.activeItem[0].scrollIntoView(true);
          }
        });
      },
      listItemSelect(item, e) {
        this.modalInputs.showValidation = false;
        if (this.modalInputs.selectedValue === item.objectId) {
          // Deselect
          this.modalInputs.selectedValue = -1;
          this.modalInputs.selectedListItem = {};
          this.modalInputs.selectedObject = {};
          e.target.classList.toggle('active');
        } else {
          // Select
          this.modalInputs.selectedValue = item.objectId;
          this.modalInputs.selectedObject = item;
          if (this.modalInputs.selectedListItem.classList) {
            this.modalInputs.selectedListItem.classList.toggle('active');
          }
          this.modalInputs.selectedListItem = e.target;
          this.modalInputs.selectedListItem.classList.toggle('active');
        }
      },
      modalCancelClick() {
        this.clearModalInputs();
      },
      async modalOkClick() {
        // if (this.modalInputs.selectedValue === -1) {
        //   e.preventDefault();
        //   this.modalInputs.showValidation = true;
        //   return;
        // }
        // Save Object
        this.modalInputs.currentGeneratedAssetInput.objectId = this.selectedValue === -1 ? null : this.modalInputs.selectedObject.objectId;
        await AuthoringSvc.saveGeneratedAssetInput(this.assetId, this.modalInputs.currentGeneratedAssetInput, this.webconUser)

        this.clearModalInputs();
      },
      clearModalInputs() {
        this.modalInputs.isLoading = true;
        this.modalInputs.currentGeneratedAssetInput = {};
        this.modalInputs.curriculumObjects = [];
        this.modalInputs.selectedValue = -1;
        this.modalInputs.selectedObject = {};
        this.modalInputs.selectedListItem = {};
        this.modalInputs.objectFilter = '';
        this.modalInputs.showValidation = false;
      },
      toggleTemplateView() {
        this.showTemplate = !this.showTemplate;
      },
      toggleDrawer() {
        this.showDrawer = !this.showDrawer;
      },
      copyToClipboard(item) {
        navigator.clipboard.writeText(item.tag);
      }
    }
  }
</script>

<style scoped>
.left-align {
  text-align: left;
}
.content-row {
  height: 100%;
}
iframe {
  width: 100%;
  height: 90vh;
}
ul {
  text-align: left;
  list-style-type: none;
}
li {
  text-decoration: none;
}
li .icon {
  padding-right: 3px;
}
li .copy-icon {
  cursor: pointer;
}
.btn {
    color: #333;
    background-color: #fdfdfd;
    border: 1px solid #ababab;
    border-radius: 0;
    padding: 6px 12px;
    font-size: 12px;
    text-align: center;
    line-height: 1.42857143;
    transition: all .05s ease-in;
    cursor: pointer;
}
.btn.showTemplate {
    margin-left: 10px;
}
.level-based-tags {
  overflow-y: hidden;
  max-height: 30vh;
}
.level-based-tags .card-body {
  width: 100%;
  min-height: 40vh;
  max-height: 40vh;
  overflow-y: scroll;
  padding: 0;
}
.template-based-tags {
  min-height: 40vh;
  max-height: 40vh;
  /* overflow-y: scroll; */
}
.drawer {
  transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
}
.drawer.hidden {
  left: -550;
}
.list-group {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid black;
}
.list-group-item:hover {
  background-color: gray;
}
.list-group-item.active:hover {
  background-color: lightskyblue;
}
.label {
  font-weight: bold;
}
.data-item {
  padding-left: 3px;
}
.right {
  text-align: right;
}
.fa-plug {
  color: red;
}
.fa-plug-circle-check {
  color: green;
}
.validation-err {
  color: red;
}
.drawer-header {
  font-weight: bold;
}
</style>