import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

console.debug(`Authoring-UI Version: ${process.env.VUE_APP_VERSION}`);

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faTrashCan,
  faFileLines,
  faFileCode,
  faTable,
  faFileAudio,
  faFileVideo,
  faFileImage,
  faFileCircleQuestion,
  faFile,
  faFilePowerpoint,
  faLink,
  faRotateLeft,
  faBackward,
  faBackwardFast,
  faForward,
  faCopy,
  faLocationCrosshairs,
  faPlug,
  faPlugCircleCheck,
  faFolder,
  faArrowDownAZ,
 } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faTrashCan,
  faFileLines,
  faFileCode,
  faTable,
  faFileAudio,
  faFileVideo,
  faFileImage,
  faFileCircleQuestion,
  faFile,
  faFilePowerpoint,
  faLink,
  faRotateLeft,
  faBackward,
  faBackwardFast,
  faForward,
  faCopy,
  faLocationCrosshairs,
  faPlug,
  faPlugCircleCheck,
  faFolder,
  faArrowDownAZ,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import router from './router';

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
