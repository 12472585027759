import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_AUTHOR_URL}`;
// const assetInputsUrl = `${rootUrl}/GeneratedAssets/inputs`;
const objectUrl = `${rootUrl}/object`;
const templateUrl = `${rootUrl}/template`;
const generatedAssetsUrl = `${rootUrl}/generatedassets`;

const author_key = `${process.env.VUE_APP_AUTHOR_ID}`;
const author_secret = `${process.env.VUE_APP_AUTHOR_SECRET}`;

let token = '';
let tokenExpire = '';

export default {
  async doAuth() {
    if (token && tokenExpire > new Date()) {
      return token;
    }
    const url = `${rootUrl}/connect/token`;
    const response = await axios(
      {
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: `grant_type=client_credentials&scope=api&client_id=${author_key}&client_secret=${author_secret}`,
      }
    );
    token = response.data.access_token;
    const dt = new Date();
    tokenExpire = new Date(dt.setMinutes(dt.getMinutes() + (response.data.expires_in / 60) - 5));
  },
  async getGeneratedAssetInputs(assetId) {
    await this.doAuth();
    const url = `${generatedAssetsUrl}/${assetId}/inputs`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return resposne.data;
  },
  async saveGeneratedAssetInputs(assetId, templateId, webconUser, assetInputs) {
    const inputModels = [];
    assetInputs.sort((a,b) => a.y - b.y);
    for (let index = 0; index < assetInputs.length; index++) {
      const assetInput = assetInputs[index];
      
      inputModels.push({
        objectId: assetInput.objectId,
        sortOrder: index,
        templateId: templateId
      });
    }

    await this.doAuth();

    const url = `${generatedAssetsUrl}/${assetId}/inputs`;
    await axios(
      {
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: inputModels,
      }
    );

  },
  async saveGeneratedAssetInput(assetId, generatedAssetInput, webconUser) {
    const url = `${generatedAssetsUrl}/${assetId}/input/${generatedAssetInput.id}`;
    await axios(
      {
        url: url,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: {
          id: generatedAssetInput.id,
          objectId: generatedAssetInput.objectId,
          sortOrder: generatedAssetInput.sortOrder
        },
      }
    );
  },
  async getObject(objectId, webconUser) {
    await this.doAuth();
    const url = `${objectUrl}/${objectId}`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        }
      }
    );
    return resposne.data;
  },
  async getTemplate(templateId, webconUser) {
    await this.doAuth();
    const url = `${templateUrl}/${templateId}`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        }
      }
    );
    return resposne.data;
  },
  async getTemplateInputs(templateId, webconUser) {
    await this.doAuth();
    const url = `${templateUrl}/${templateId}/inputs`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        }
      }
    );
    return resposne.data;
  },
  async getAsset(assetId, webconUser) {
    await this.doAuth();
    const url = `${generatedAssetsUrl}/${assetId}`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        }
      }
    );
    return resposne.data;
  }
}