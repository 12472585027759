<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-card-header>Source List</b-card-header>
          <b-card-body>
            <b-tree-view
              :data="defaultData"
              :contextMenu="true"
              :contextMenuItems="contextMenuItems"
              :renameNodeOnDoubleClick="false"
              prependIconClass="fas"
              :showIcons="true"
              :nodesDragable="true">
            </b-tree-view>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-card-header>Curriculum Hierarchy</b-card-header>
          <b-card-body>
            <b-tree-view
              :data="treeData"
              :contextMenu="false"
              :renameNodeOnDoubleClick="true"
              prependIconClass="fas"
              :showIcons="true"
              :nodesDragable="true">
            </b-tree-view>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { bTreeView } from 'bootstrap-vue-treeview';

  export default {
    components: { bTreeView },
    data() {
      return {
        defaultData: [
          {
            id: 1,
            name: 'Curriculum Root',
            icon: 'fa-folder',
            children: [
              {
                id: 2,
                name: 'Static Document',
                icon: 'fa-thin fa-file-lines',
              }
            ]
          }
        ],
        treeData: [
          {
            id: 1,
            name: 'Curriculum Root',
            icon: 'fas fa-folder',
            children: [
              {
                id: 2,
                name: 'Science',
                icon: 'fas fa-folder',
              }
            ]
          }
        ],
        contextMenuItems: [
          {code: 'ADD_CHILD_NODE', label: 'Add child'},
          {code: 'DELETE_NODE', label: 'Delete node'},
          {code: 'RENAME_NODE', label: 'Rename'}
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>